import { useBaseXyz } from '@local/webviz/dist/context';
import { MetadataState, Nullable, PlotState, UpdateSnapshot } from '@local/webviz/dist/types';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { ElementClass, toSuffixUid } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import isFinite from 'lodash-es/isFinite';
import merge from 'lodash-es/merge';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'src/store/store';
import { loadedObjects } from 'src/store/visualization/visualizationSlice';
import { createViewSnapshot } from 'src/visualization/context/snapshots/generateSnapshot';

import { categoryDataSnapshot } from '../../../context/snapshots/categoryDataSnapshot';

function getIdByElementClass(
    snapshot: UpdateSnapshot,
    entityClass: ElementClass,
): Nullable<string> {
    const entry = Object.entries(snapshot).find(
        // eslint-disable-next-line no-underscore-dangle
        ([_key, value]) => value && value.__class__ === entityClass,
    );
    if (!entry) {
        return null;
    }
    return entry[0];
}

export function ObjectLoader() {
    const {
        setStateFromSnapshot,
        addListener,
        addViewToPlotDirectly,
        removeViewsFromPlotDirectly,
        getEntityState,
    } = useBaseXyz();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const objectsToLoad = useAppSelector(loadedObjects);

    const [initialSnapshot, setInitialSnapshot] = useState<UpdateSnapshot>({});

    useEffect(() => {
        const plot = getEntityState('plot') as PlotState;
        // copy array of existing views and remove them in sync with objectsToLoad
        const remainingPlotViews = [...plot.views];
        objectsToLoad.forEach((gooseResponse) => {
            if (gooseResponse?.object) {
                const viewSnapshot = createViewSnapshot(gooseResponse, {
                    objectId: gooseResponse.object_id,
                    workspaceId,
                    orgId,
                });

                if (remainingPlotViews.includes(viewSnapshot.viewId)) {
                    remainingPlotViews.splice(remainingPlotViews.indexOf(viewSnapshot.viewId), 1);
                    return;
                }

                if (viewSnapshot?.viewId && viewSnapshot?.snapshot) {
                    setStateFromSnapshot(viewSnapshot.snapshot, {});
                    setInitialSnapshot(viewSnapshot.snapshot);
                    addViewToPlotDirectly(viewSnapshot.viewId);
                    remainingPlotViews.splice(remainingPlotViews.indexOf(viewSnapshot.viewId), 1);
                }
            }
        });

        if (remainingPlotViews.length > 0) {
            removeViewsFromPlotDirectly(remainingPlotViews);
        }
    }, [objectsToLoad]);

    const listeners: (() => void)[] = [];
    useEffect(() => {
        // TODO: handle multiple Tileset3D elements in the same scene when applicable
        const elementId = getIdByElementClass(initialSnapshot, ElementClass.Tileset3D);
        if (!elementId) {
            return () => {};
        }

        const removeElementMetadataListener = addListener(
            elementId,
            'metadata',
            async (attributes) => {
                if (!attributes?.attributes_metadata) return;
                let { attributes_metadata: attributesMetadata } = attributes;

                const downloadMetadataRequests: Promise<void>[] = [];
                const downloadedMetadata: MetadataState = {};
                const attributeIds = Object.keys(attributesMetadata);
                attributeIds.forEach((attributeId) => {
                    if ('uri' in attributesMetadata[attributeId]) {
                        const { uri } = attributesMetadata[attributeId];
                        downloadMetadataRequests.push(
                            fetch(uri)
                                .then(async (response) => {
                                    if (!response.ok) {
                                        throw new Error(
                                            `Failed to download attribute ${attributeId} with status code ${response.status}`,
                                        );
                                    }
                                    const metadata = await response.json();
                                    downloadedMetadata[attributeId] = metadata;
                                    delete attributesMetadata[attributeId].uri;
                                })
                                .catch((error) => console.error(error)),
                        );
                    }
                });
                await Promise.all(downloadMetadataRequests);
                attributesMetadata = merge(attributesMetadata, downloadedMetadata);
                const attributesSnapshot = attributeIds.reduce(
                    (accumulator: UpdateSnapshot, attributeId: string) => {
                        if ('metadata' in attributesMetadata[attributeId]) {
                            const { metadata } = attributesMetadata[attributeId];
                            if ('min' in metadata && 'max' in metadata) {
                                const { min, max } = metadata;
                                const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
                                const minValue = isFinite(min) ? min : -Infinity;
                                const maxValue = isFinite(max) ? max : +Infinity;
                                return {
                                    ...accumulator,
                                    [mappingId]: {
                                        data_control_values: [
                                            minValue,
                                            minValue,
                                            maxValue,
                                            maxValue,
                                        ],
                                    },
                                };
                            }
                            if ('lookup_table' in metadata) {
                                const { lookup_table: lookupTable } = metadata;
                                return {
                                    ...accumulator,
                                    ...categoryDataSnapshot(attributeId, lookupTable),
                                };
                            }
                        }
                        return { ...accumulator };
                    },
                    {},
                );
                setStateFromSnapshot(attributesSnapshot, {});
            },
        );
        listeners.push(removeElementMetadataListener);

        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, [initialSnapshot]);

    return null;
}
