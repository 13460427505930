import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PlusIcon } from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ID, useObjectManager } from 'src/visualization/context/hooks/useObjectManager';

import { SchemaIcon } from '../../../../pages/workspacePage/workspaceContent/SchemaIcon';
import { fileNameExtensionRemover } from '../../../../pages/workspacePage/workspaceContent/utils';
import { useStyles } from './ObjectListItemControl.styles';
import { ObjectListItemControlProps } from './ObjectListItemControl.types';

const APPROXIMATE_MAX_LOAD_TIME = 15000;

export function ObjectListItemControl({
    objectListItem,
    selectedObjectId,
}: ObjectListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID) ?? '';

    const { object_id: objectId, name, schema } = objectListItem;
    const { loadObject, isLoading, isError, isSuccess, isObjectOnPlotByObjectId } =
        useObjectManager(objectId);

    useEffect(() => {
        if (objectIdQueryParam === objectId) {
            loadObject(objectIdQueryParam);
        }
    }, [objectIdQueryParam]);

    const isObjectOnPlot = isObjectOnPlotByObjectId(objectId);
    const itemControlRef = useRef<HTMLDivElement>(null);
    const Icon = SchemaIcon(schema);

    return (
        <Grid
            item
            container
            className={classnames(classes.root, { [classes.errorContainer]: false })}
        >
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isLoading,
                    [classes.backgroundClear]: isSuccess || isError,
                    [classes.backgroundSelectedForLoading]: objectId === selectedObjectId,
                })}
                ref={itemControlRef}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.mainContainerOutlined]: false,
                    })}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="stretch"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon />
                        </Grid>
                        <Typography variant="body2" color="inherit" className={classes.name}>
                            {fileNameExtensionRemover(name)}
                        </Typography>
                    </Grid>
                    {isLoading && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={isLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!isObjectOnPlot && (
                        <IconButton
                            onClick={() => loadObject(objectId)}
                            className={classnames(classes.loadIconButton)}
                        >
                            <PlusIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
