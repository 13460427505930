import { DragTab } from '@local/web-design-system/dist/components/DragTab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { useMemo, useRef } from 'react';
import Split from 'react-split';

import {
    MIN_PANEL_HEIGHT_PX,
    MY_SCENE_PANEL_HEIGHT_PERCENTAGE,
    OBJECTS_PANEL_HEIGHT_PERCENTAGE,
    PROJECT_TREE,
} from './ObjectsPanel.constants';
import { useStyles } from './ObjectsPanel.styles';
import { ObjectLoader } from './ProjectTree/ObjectLoader/ObjectLoader';
import { ProjectTreePanel } from './ProjectTree/ProjectTreePanel';
import { MyScenePanel } from './ScenePanel/ScenePanel';

export function ObjectsPanel() {
    const { classes } = useStyles();
    const gutterRef = useRef(null);

    return useMemo(
        () => (
            <Grid
                container
                className={classes.root}
                direction="column"
                wrap="nowrap"
                alignItems="stretch"
            >
                <Split
                    sizes={[OBJECTS_PANEL_HEIGHT_PERCENTAGE, MY_SCENE_PANEL_HEIGHT_PERCENTAGE]}
                    minSize={MIN_PANEL_HEIGHT_PX}
                    direction="vertical"
                    cursor="row-resize"
                    className={classes.splitContainer}
                    gutter={() => gutterRef.current}
                >
                    <Grid item container direction="column">
                        <Grid item className={classes.titleBox}>
                            <Typography textAlign="center" className={classes.titleText}>
                                {PROJECT_TREE}
                            </Typography>
                        </Grid>
                        <ProjectTreePanel />
                    </Grid>

                    <Grid item className={classes.myScenePanelContainer}>
                        <Grid item container ref={gutterRef}>
                            <DragTab className={classes.gutter} />
                        </Grid>
                        <MyScenePanel />
                    </Grid>
                    <ObjectLoader />
                </Split>
            </Grid>
        ),
        [gutterRef],
    );
}
