import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { checkIsAdmin } from '@local/login';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { DragAndDrop } from '@local/web-design-system/dist/components/DragAndDrop/DragAndDrop';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { ListItem } from '@local/web-design-system/dist/components/GenericListing';
import type { FieldDefinition } from '@local/web-design-system/dist/components/GenericListing/types';
import { PaginatedList } from '@local/web-design-system/dist/components/PaginatedList';
import { Pagination } from '@local/web-design-system/dist/components/PaginationControls/PaginationControls';
import { BlankTableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton/BlankTableLoadingSkeleton';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import { WorkspaceSearch } from '@local/workspaces/src/pages/workspaceListingPage/WorkspaceSearch';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useListFilesQuery } from 'src/apiClients/enhancedFileMiddleware';
import { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { PageContent } from 'src/components/pageContent/PageContent';
import { WorkspaceContext, useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileDragAndDrop } from 'src/hooks/useFileDragAndDrop';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { usePagination } from 'src/hooks/usePagination';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { Sidebar } from 'src/pages/workspaceFilesPage/sidebar/Sidebar';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import { NotFound } from 'src/pages/workspacePage/workspaceNotFound/WorkspaceNotFound';
import {
    FILES,
    SEARCH_PLACEHOLDER,
    UPLOAD_FILES,
    FILES_CONTENT_NO_CONTENT,
    FILES_CONTENT_NO_CONTENT_DESC,
} from 'src/strings';
import {
    WORKSPACE_FILES_BREADCRUMB,
    workspaceListingWithLinkBreadcrumb,
} from 'src/utils/breadcrumbs';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';
import { hasRoleOrHigher } from 'src/utils/permissions';

import { fileDefinition } from '../workspacePage/FieldRowDefinitions';
import { FileActions } from './fileActions/FileActions';
import { ProgressPanel } from './ProgressPanel';
import { UploadFilesButton } from './UploadFilesButton';
import { useStyles } from './WorkspaceFilesPage.styles';

interface FilesListProps {
    files: ListFile[];
    selectedFile: string | undefined;
    isLoading: boolean;
    fields?: FieldDefinition<any>[];
    handleSelectFile: (file: ListFile | undefined) => void;
    isEditor: boolean;
}

const FilesList = ({
    isLoading,
    selectedFile,
    files,
    fields = fileDefinition,
    handleSelectFile,
    isEditor,
}: FilesListProps) => {
    const { classes } = useStyles();

    if (isLoading) {
        return <BlankTableLoadingSkeleton />;
    }

    if (!isLoading && !files?.length) {
        return (
            <EmptyState
                title={FILES_CONTENT_NO_CONTENT}
                message={FILES_CONTENT_NO_CONTENT_DESC}
                image={<NotFoundSvg />}
                classes={{ root: classes.noSearchResults }}
            />
        );
    }

    return (
        <PaginatedList
            header={<TableHeader fields={fields} hasActions hasDragAndDrop={isEditor} />}
            content={files.map((file) => {
                const isSelected = selectedFile === file.file_id;
                const handleClick = () => handleSelectFile(isSelected ? undefined : file);

                return (
                    <ListItem
                        key={file.file_id}
                        item={file}
                        fields={fields}
                        clickHandler={handleClick}
                        actions={<FileActions file={file} />}
                        selected={isSelected}
                        automation-id={`file-row-${file.file_id}-actions`}
                        classes={{ root: classes.listItemOverrides }}
                    />
                );
            })}
            isEmpty={isLoading ? false : files.length === 0}
            stickyHeader
        />
    );
};

export const WorkspaceFilesPage = ({ tabs }: { tabs: JSX.Element }) => {
    const params = useParams();
    const { classes } = useStyles();
    const [id, setID] = usePersistedState('id');
    const { evouiWorkspaceFiles } = useFlags();
    const {
        page,
        pageSize,
        searchTerm,
        handleNextPage,
        handlePreviousPage,
        handlePageSizeChange,
        handleSearch,
    } = usePagination();
    const { setSidebarContent } = useSidebarContext();
    const contextValue = useWorkspaceContext();

    const isAdmin = checkIsAdmin();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { isOpen: isUploadContainerOpen } = useFileUploadContainer({ workspaceId });

    const { handleFileChange, handleDragEnter, handleDragLeave, isHighlighted } =
        useFileDragAndDrop(workspaceId, orgId);

    const isEditor = contextValue.workspaceUserRole
        ? hasRoleOrHigher(contextValue.workspaceUserRole, 'editor')
        : false;

    const {
        data: workspace,
        isLoading: isLoadingWorkspace,
        isError: isErrorWorkspace,
    } = fetchWorkSpaceData(params, isAdmin);

    const {
        data: files,
        isFetching: isLoadingFiles,
        isError: isErrorFiles,
    } = useListFilesQuery({
        organisationId: orgId,
        workspaceId,
        limit: pageSize,
        offset: page * pageSize,
        ...(searchTerm !== '' && { fileName: searchTerm }),
    });

    useEffect(() => {
        setDocumentTitle(workspace?.name ?? '');
    }, [workspace?.name]);

    const handleSelectFile = (file: ListFile | undefined) => {
        setID(file ? file.file_id : undefined);
    };

    const segments: BreadcrumbSegment[] = [
        ...workspaceListingWithLinkBreadcrumb(params),
        {
            name: workspace?.name ?? '',
            path: `../${WORKSPACES_OVERVIEW_PAGE}`,
        },
        { name: WORKSPACE_FILES_BREADCRUMB },
    ];

    useEffect(() => {
        if (workspace) {
            const selectedFile = id ?? undefined;
            setSidebarContent(
                <WorkspaceContext.Provider value={contextValue}>
                    <Sidebar selectedFile={selectedFile} />
                </WorkspaceContext.Provider>,
            );
        }

        return () => {
            // Clear the sidebar when leaving the page
            setSidebarContent(undefined);
        };
    }, [id, workspaceId, workspace, contextValue]);

    // If the user ends up on an empty page, take them back a page. This can happen when you delete the last file
    // on a page.
    useEffect(() => {
        if (files?.files && files.files.length === 0 && page > 0) {
            handlePreviousPage();
        }
    }, [files, page]);

    if (!evouiWorkspaceFiles) {
        return null;
    }

    if (
        isErrorWorkspace ||
        isErrorFiles ||
        (!isLoadingWorkspace && !isLoadingFiles && !workspace)
    ) {
        return <NotFound />;
    }

    return (
        <Grid container wrap="nowrap" direction="row" className={classes.pageContainer}>
            <Grid container wrap="nowrap" direction="column" className={classes.page}>
                <PageContent
                    breadcrumbSegments={segments}
                    breadcrumbsLoading={isLoadingWorkspace}
                    pageTitle={workspace?.name ?? ''}
                    pageTitleLoading={isLoadingWorkspace}
                    titleActions={tabs}
                >
                    <Grid item display="flex" flexDirection="column" flexGrow="1">
                        <div className={classes.headerContainer}>
                            <Grid
                                item
                                className={classes.tableHeader}
                                justifyContent="space-between"
                            >
                                {FILES}
                            </Grid>
                            <Grid
                                container
                                className={classes.tableSubheader}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <div>
                                    <Pagination
                                        numberOfRows={files?.total || 0}
                                        rowsPerPage={pageSize}
                                        handleBackButtonClick={handlePreviousPage}
                                        handleNextButtonClick={handleNextPage}
                                        handleChangeRowsPerPage={handlePageSizeChange}
                                        ariaLabelNext="Next page"
                                        ariaLabelPrevious="Previous page"
                                        page={page}
                                        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                                        isLoading={isLoadingFiles}
                                        showPrefix
                                        labelEnabled={false}
                                    />
                                </div>
                                <Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.tableSubheaderRight}
                                    >
                                        <Grid item>
                                            <WorkspaceSearch
                                                setSearchTerm={handleSearch}
                                                searchPlaceHolderValue={SEARCH_PLACEHOLDER}
                                                userAction={
                                                    UserActionWorkspace.WORKSPACE_FILES_SEARCH
                                                }
                                                userActionCategory={
                                                    CategoriesWorkspace.WORKSPACE_FILES_PAGE
                                                }
                                            />
                                        </Grid>
                                        <Grid item automation-id="upload-file">
                                            <UploadFilesButton
                                                buttonText={UPLOAD_FILES}
                                                disabled={isUploadContainerOpen}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <DragAndDrop
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDrop={handleFileChange}
                            enabled={isEditor}
                        >
                            <Grid className={classes.tableContainer}>
                                <div
                                    className={classNames([
                                        classes.table,
                                        isHighlighted ? classes.highlight : null,
                                    ])}
                                >
                                    <FilesList
                                        handleSelectFile={handleSelectFile}
                                        selectedFile={id}
                                        files={files?.files ?? []}
                                        fields={fileDefinition}
                                        isLoading={isLoadingFiles || isLoadingWorkspace}
                                        isEditor={isEditor}
                                    />
                                </div>
                                {isUploadContainerOpen && evouiWorkspaceFiles && (
                                    <ProgressPanel workspaceId={workspaceId} />
                                )}
                            </Grid>
                        </DragAndDrop>
                    </Grid>
                </PageContent>
            </Grid>
        </Grid>
    );
};
