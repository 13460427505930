import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import FileIcon from '@local/web-design-system/dist/icons/Files/FileIcon';
import UploadIcon from '@local/web-design-system/dist/icons/Files/UploadIcon';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useCustomUploadFileByIdMutation } from 'src/apiClients/customFileEndpoints';
import { type DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { FileActions } from 'src/pages/workspaceFilesPage/fileActions/FileActions';
import { UPLOAD_NEW_VERSION, FILE_HISTORY, INSUFFICIENT_WORKSPACE_PERMISSION } from 'src/strings';
import { FileStatus, UploadStatus } from 'src/types/files';
import { hasRoleOrHigher } from 'src/utils/permissions';

interface Props {
    file?: DownloadFileResponse;
}

export const VersionHistory = ({ file }: Props) => {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const params = useParams();
    const [updateFile] = useCustomUploadFileByIdMutation();
    const { workspaceUserRole, workspaceId } = useWorkspaceContext();
    const uploadFieldRef = useRef<HTMLInputElement>(null);

    const { uploadingCount, upsertFileStatus } = useFileUploadContainer({ workspaceId });

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (!file?.versions) {
        return null;
    }

    const handleUploadFile = async (currentFileStatus: FileStatus, updatedVersion: File) => {
        const response = await updateFile({
            workspaceId,
            organisationId,
            fileId: file.file_id,
            updatedVersion: updatedVersion as File,
        });
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: 'error' in response ? UploadStatus.Failed : UploadStatus.Uploaded,
            percentCompleted: 100,
        });
        return response;
    };

    const handleError = async (
        error: FetchBaseQueryError | SerializedError,
        updatedFile: File,
        currentFileStatus: FileStatus,
    ) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: 'message' in error ? error.message : String(error),
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} uploading file "${updatedFile.name}"`);
        return Promise.reject(error);
    };

    const organisationId = getOrgUuidFromParams(params);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.currentTarget.files;
        if (!fileList || fileList.length < 1) {
            return;
        }

        const uploadPromises = Array.from(fileList).map(async (updatedFile) => {
            const currentFileStatus: FileStatus = {
                fileName: updatedFile.name,
                uploadStatus: UploadStatus.Uploading,
                size: updatedFile.size,
                // using a UUID here so that the fileId is unique for version when in the progress container
                // Without this the previous state of the container is overwritten
                fileId: uuid(),
                percentCompleted: 0,
            };

            upsertFileStatus(currentFileStatus);
            if (uploadFieldRef.current) {
                uploadFieldRef.current.value = '';
            }

            const response = await handleUploadFile(currentFileStatus, updatedFile);
            if ('error' in response) {
                return handleError(response.error, updatedFile, currentFileStatus);
            }
            return response.data;
        });

        await Promise.all(uploadPromises);
    };

    return (
        <div className={classes.versionHistoryContainer}>
            <Header text={FILE_HISTORY} automationId="file-history-label" />
            <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
                <span>
                    <Button
                        component="label"
                        variant="contained"
                        className={classes.uploadNewVersion}
                        color="inherit"
                        size="large"
                        disabled={uploadingCount > 0 || isViewer}
                        automation-id="upload-new-version-button"
                        fullWidth
                    >
                        <UploadIcon />
                        <Typography fontSize="small">{UPLOAD_NEW_VERSION}</Typography>
                        <input
                            ref={uploadFieldRef}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </Button>
                </span>
            </Tooltip>

            <div className={classes.versionHistoryList}>
                {file.versions.map((version) => (
                    <div key={version.version_id} className={classes.version}>
                        <Grid direction="column" container>
                            <div className={classes.versionHistory}>
                                <FileIcon color="primary" />
                                <div className={classes.versionHistoryItem}>
                                    <Typography
                                        className={classes.versionHistoryTitle}
                                        automation-id="version-history-file-name"
                                        title={file.name}
                                    >
                                        {file.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.versionHistoryValue}
                                        automation-id="version-history-id"
                                    >
                                        ID: {version.version_id}
                                    </Typography>
                                    <span className={classes.versionHistoryValue}>
                                        <PropertyDate
                                            timestamp={version.created_at}
                                            automationId="version-history-created-at"
                                        />
                                    </span>
                                </div>
                                <FileActions file={file} version={version} />
                            </div>
                        </Grid>
                    </div>
                ))}
            </div>
        </div>
    );
};
