import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';
import type { MenuItemConfig } from '@local/web-design-system/dist/components/Menu/Menu.types';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { CopyIcon } from '@local/web-design-system/dist/icons/Actions/CopyIcon';
import { DeleteIcon } from '@local/web-design-system/dist/icons/Actions/DeleteIcon';
import { WarningIcon } from '@local/web-design-system/dist/icons/Alerts/WarningIcon';
import { DownloadIcon } from '@local/web-design-system/dist/icons/Files/DownloadIcon';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useDeleteFileByIdMutation,
    useLazyGetFileByIdQuery,
} from 'src/apiClients/enhancedFileMiddleware';
import type { FileVersionResponse, ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import {
    COPY,
    DELETE,
    DELETE_FILE_CANCEL,
    DELETE_FILE_CONFIRM,
    DELETE_FILE_DIALOG_CONTENT,
    DELETE_FILE_DIALOG_TITLE,
    DELETE_FILE_FAILED,
    DOWNLOAD,
    COPIED_TO_CLIPBOARD,
    DOWNLOAD_FILE_FAILED,
    INSUFFICIENT_WORKSPACE_PERMISSION,
} from 'src/strings';
import { hasRoleOrHigher } from 'src/utils/permissions';

import { useStyles } from './FileActions.styles';

interface ActionProps {
    iconOnly?: boolean;
    onClick?: () => void;
}

const DownloadAction = ({ iconOnly, onClick }: ActionProps) => {
    const { classes } = useStyles();

    if (iconOnly && onClick) {
        return (
            <Tooltip title={DOWNLOAD}>
                <IconButton
                    className={classes.iconButton}
                    automation-id="download-button"
                    onClick={onClick}
                >
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <DownloadIcon />
            <Typography className={classes.actionText}>{DOWNLOAD}</Typography>
        </Grid>
    );
};

const DeleteAction = ({ iconOnly, onClick }: ActionProps) => {
    const { classes } = useStyles();
    const { workspaceUserRole } = useWorkspaceContext();

    const disabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (iconOnly && onClick) {
        return (
            <Tooltip title={disabled ? INSUFFICIENT_WORKSPACE_PERMISSION : DELETE}>
                <span>
                    <IconButton
                        className={classes.iconButton}
                        automation-id="delete-button"
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <DeleteIcon />
            <Typography className={classes.actionText}>{DELETE}</Typography>
        </Grid>
    );
};

const CopyAction = () => {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <CopyIcon />
            <Typography className={classes.actionText}>{COPY}</Typography>
        </Grid>
    );
};

interface Props {
    file: Pick<ListFile, 'file_id' | 'name' | 'version_id'>;
    /** If provided, will be used for context when issuing download or delete queries */
    version?: FileVersionResponse;
    /** Whether to display icons, otherwise will display a dropdown menu of options */
    icons?: boolean;
}

export const FileActions = ({ file, version, icons }: Props) => {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const params = useParams();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const { workspaceUserRole } = useWorkspaceContext();

    const [fetchFile, { isLoading: isDownloading }] = useLazyGetFileByIdQuery();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileByIdMutation();

    const isDisabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    const handleDownload = async () => {
        const { data, isError, error } = await fetchFile({
            organisationId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            fileId: file.file_id,
            versionId: version?.version_id,
        });

        if (isError) {
            trackError('Error downloading file', JSON.stringify(error));
            addMessage({
                message: DOWNLOAD_FILE_FAILED,
                type: NotificationType.ERROR,
            });
            return;
        }

        if (!data) {
            return;
        }

        // When the download button is clicked, we fetch the file by ID from the API, which gives us the
        // download link. This code is to automatically trigger that download link, instead of presenting the
        // user with another download button to click again.
        const downloadLink = document.createElement('a');
        downloadLink.href = data.download;
        downloadLink.download = file.name;
        downloadLink.click();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(version!.version_id);
        addMessage({
            message: COPIED_TO_CLIPBOARD,
            type: NotificationType.SUCCESS,
        });
    };

    const menuOptions: MenuItemConfig[] = [
        {
            key: 'download-file',
            ItemComponent: DownloadAction,
            action: handleDownload,
        },
    ];

    // Individual versions of a file cannot be deleted, only the entire file can.
    if (!version) {
        menuOptions.push({
            key: 'delete-file',
            ItemComponent: DeleteAction,
            action: () => setDeleteDialogOpen(true),
            isDisabled: () => isDisabled,
        });
    }

    if (version) {
        menuOptions.push({
            key: 'copy-file-id',
            ItemComponent: CopyAction,
            action: handleCopy,
        });
    }

    const handleDelete = async (isConfirmed: boolean | null) => {
        setDeleteDialogOpen(false);

        if (!isConfirmed || isDisabled) {
            return;
        }
        try {
            await deleteFile({
                organisationId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
                fileId: file.file_id,
            }).unwrap();

            // todo usePersistedState should be able to do this but it isn't working, investigate this
            /** @see https://seequent.atlassian.net/browse/CENPLAT-19894 */
            searchParams.delete('id');
            setSearchParams(searchParams);
        } catch (error) {
            trackError('Error deleting file', JSON.stringify(error));
            addMessage({
                message: DELETE_FILE_FAILED,
                type: NotificationType.ERROR,
            });
        }
    };

    const Dialogs = () => (
        <Dialog
            open={deleteDialogOpen}
            action={handleDelete}
            confirmText={DELETE_FILE_CONFIRM}
            dialogTitle={DELETE_FILE_DIALOG_TITLE}
            icon={<WarningIcon color="warning" />}
            dialogContent={DELETE_FILE_DIALOG_CONTENT}
            cancelText={DELETE_FILE_CANCEL}
        />
    );

    if (isDeleting || isDownloading) {
        if (icons) {
            return (
                <div className={classes.icons}>
                    <Skeleton variant="circle" width={38} height={38} />
                </div>
            );
        }
        return <Skeleton variant="circle" width={38} height={38} />;
    }

    if (icons) {
        // Render an inline list of icon buttons only
        return (
            <>
                <div className={classes.icons}>
                    {menuOptions.map((menuOption) => {
                        const { ItemComponent } = menuOption;

                        return (
                            <ItemComponent
                                key={menuOption.key}
                                onClick={() => menuOption.action?.(menuOption.key)}
                                userRole={workspaceUserRole}
                                iconOnly
                            />
                        );
                    })}
                </div>
                <Dialogs />
            </>
        );
    }

    // Render a menu with dropdown options
    return (
        <>
            <EllipsisMenu menuOptions={menuOptions} />
            <Dialogs />
        </>
    );
};
