import { getConfig } from '@local/app-config';
import { ContentArea } from '@local/content-area';
import { getAdminFromToken, useGetUserDetailsQuery } from '@local/login';
import {
    getAccountAdminFromTokenBentley,
    getCurrentUserDetails,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { MaintenanceState } from '@local/svgs/dist/pageState';
import { WithTerms } from '@local/terms-of-use';
import { ConnectivityCheck } from '@local/web-design-system/dist/components/ConnectivityCheck';
import type { NavDrawerItem } from '@local/web-design-system/dist/components/NavDrawer';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid/Grid';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { DISCOVER_PAGE, USER_MANAGEMENT_PAGE, WEBVIZ_PLOT } from 'src/urls';

import { EvoIcon } from '../../assets/EvoBadge';
import { NO_INTERNET_CONNECTION, TAGLINE, VERSION_LABEL, SCAFFOLDING_TITLE } from '../../strings';
import { useStyles } from './ContentScaffolding.styles';
import { OrgSelector } from './OrgSelector/OrgSelector';

export const ContentScaffolding = () => {
    const { evouiEnableDiscover, evouiMaintenancePage } = useFlags();
    const { classes } = useStyles();
    const location = useLocation();
    const params = useParams();

    let user;
    if (getConfig().bentleyIdEnabled) {
        user = getCurrentUserDetails();
    } else {
        user = useGetUserDetailsQuery();
    }

    setDocumentTitle(SCAFFOLDING_TITLE);

    const isAdmin = getConfig().bentleyIdEnabled
        ? getAccountAdminFromTokenBentley()
        : getAdminFromToken();

    const navItems: NavDrawerItem[] = [
        {
            key: 'workspaces-listing',
            text: 'Workspaces',
            location: `/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`,
            icon: 'workspace',
        },
    ];
    // TODO - replace 'icon: map' with the correct icon from design
    if (evouiEnableDiscover) {
        navItems.push({
            key: 'discover',
            text: 'Discover',
            location: `/${params.orgUuid}/${DISCOVER_PAGE}`,
            icon: 'map',
        });
    }

    if (isAdmin || getConfig().bentleyIdEnabled) {
        navItems.push({
            key: 'user-management',
            text: 'Users',
            location: `/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`,
            icon: 'users',
        });
    }

    const footerComponent = !location.pathname.includes(WEBVIZ_PLOT) ? (
        <Grid automation-id="user-portal-version" className={classes.footer}>
            {TAGLINE}&nbsp;&nbsp;&nbsp;{VERSION_LABEL}
        </Grid>
    ) : undefined;

    return evouiMaintenancePage ? (
        <MaintenanceState appName="Evo" />
    ) : (
        <ContentArea
            currentRoute={location.pathname}
            evoSelector={<OrgSelector />}
            logoOnly={
                <div className={classes.appLogo}>
                    <EvoIcon />
                </div>
            }
            logoWithTitle={
                <div className={classes.appLogoLong}>
                    <img src="/SeequentEvoWhite.svg" alt="SeequentEvo" />
                </div>
            }
            footerComponent={footerComponent}
            navItems={navItems}
            service="evo"
            // TODO: Change the type of userResult to User instead of LoadingHookUser
            userResult={user}
            isAdmin={isAdmin}
            variant="light"
            exactMatch={false}
        >
            <div
                className={classNames(classes.contentInner, {
                    [classes.webVizContent]: location.pathname.includes(WEBVIZ_PLOT),
                })}
            >
                <WithTerms appName="Evo">
                    <ConnectivityCheck offlineMessage={NO_INTERNET_CONNECTION} />
                    <Outlet />
                </WithTerms>
            </div>
        </ContentArea>
    );
};

export default ContentScaffolding;
