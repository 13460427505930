import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAppSelector } from 'src/store/store';
import { loadedObjects } from 'src/store/visualization/visualizationSlice';

import { EMPTY_STATE_MESSAGE, PANEL_HEADER } from './ScenePanel.constants';
import { useStyles } from './ScenePanel.styles';

export function MyScenePanel() {
    const { classes } = useStyles();

    const loadedGooseObjects = useAppSelector(loadedObjects);

    return (
        <Grid container item direction="column" className={classes.root}>
            <PanelHeader />
            <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                className={classes.objectsList}
            >
                {/*
                TODO: add scene list when ready
                 */}
                {loadedGooseObjects.length === 0 && <EmptyStateMessage />}
            </Grid>
        </Grid>
    );
}

function PanelHeader() {
    const { classes } = useStyles();
    const applyTrace = useTrace('my-scene-panel-header');

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Typography className={classes.panelHeader}>{PANEL_HEADER}</Typography>
            <Grid {...applyTrace()} />
        </Grid>
    );
}

function EmptyStateMessage() {
    const { classes } = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.emptyStateMessageContainer}
        >
            <Typography align="center" className={classes.emptyStateMessage}>
                {EMPTY_STATE_MESSAGE}
            </Typography>
        </Grid>
    );
}
