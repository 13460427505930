import { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useContext, useState } from 'react';

import { GooseListContext } from '../../context/gooseContext/gooseContext';
import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './ProjectTreePanel.styles';

export function SkeletonObjectsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel() {
    const { classes } = useStyles();

    const { data } = useContext(GooseListContext);
    const [selectedObjectId, setSelectedObjectId] = useState<string | undefined>();

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            {data.objects.map((child: ListedObject) => (
                <Grid key={child.object_id} onClick={() => setSelectedObjectId(child.object_id)}>
                    <ObjectListItemControl
                        objectListItem={child}
                        selectedObjectId={selectedObjectId}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
