import { useListObjectsQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import {
    XyzContext,
    XyzInstanceContextValue,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useObjectFilterParams } from '../../hooks/useObjectFilterParams';
import { useObjectSearchParams } from '../../hooks/useObjectSearchParams';
import { NETWORK_ERROR_DESCR, NETWORK_ERROR_TITLE } from '../../strings';
import {
    GooseList,
    GooseListContext,
    GooseListInitial,
} from '../context/gooseContext/gooseContext';
import { ObjectsPanel } from '../ObjectsPanel/ObjectsPanel';
import { SkeletonObjectsPanelContents } from '../ObjectsPanel/ProjectTree/ProjectTreePanel';
import { Plot } from '../Plot/Plot';

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    const [gooseObjectsContextValue, setGooseObjectsContextValue] =
        useState<GooseList>(GooseListInitial);

    const params = useParams();
    const { objectName } = useObjectSearchParams();
    const { filters } = useObjectFilterParams();

    const {
        data: gooseData,
        isFetching,
        isError,
        isSuccess,
    } = useListObjectsQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        ...filters,
        objectName,
        orderBy: undefined,
        offset: 0,
        limit: 100,
    });

    useEffect(() => {
        if (!isFetching) {
            setGooseObjectsContextValue({ data: gooseData, isFetching, isError, isSuccess });
        }
    }, [isFetching]);

    const projectTree = (
        <Grid item xs zIndex={1}>
            {xyzInstanceInitialized && isSuccess && <ObjectsPanel />}
            {xyzInstanceInitialized && isFetching && <SkeletonObjectsPanelContents />}
        </Grid>
    );

    const plot = (
        <Plot
            initialized={xyzInstanceInitialized}
            onInitialized={(xyzInstance: XyzInstance) =>
                setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
            }
        />
    );

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            <GooseListContext.Provider value={gooseObjectsContextValue}>
                <SplitLayout leftPanelComponent={projectTree} rightPanelComponent={plot} />
            </GooseListContext.Provider>
        </XyzContext.Provider>
    );
}
