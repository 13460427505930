import { getConfig } from '@local/app-config';
import { ActionButton } from '@local/content-area/dist/TitleRow/buttons/ActionButton';
import { getAdminFromToken, getAccountAdminFromTokenBentley } from '@local/login';
import { BentleyGenericUserList } from '@local/user-manage/dist/components/GenericUserList/BentleyGenericUserList';
import { GenericUserList } from '@local/user-manage/dist/components/GenericUserList/GenericUserList';
import { BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { AddUserIcon } from '@local/web-design-system/dist/icons/User';
import { Restricted } from '@local/workspaces/dist/components/Restricted/Restricted';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usersBreadcrumb } from 'src/utils/breadcrumbs';

import {
    INVITE_USERS,
    RESTRICTED_NO_PERMISSION_USERS,
    USERS_TITLE,
    ADD_USER_TOOLTIP,
} from '../../strings';
import { USER_INVITE_PAGE, USER_MANAGEMENT_PAGE } from '../../urls';

function InviteUsersButton() {
    const params = useParams();

    // SQID version of button which opens add user page in Evo
    if (!getConfig().bentleyIdEnabled) {
        return (
            <ActionButton
                text={INVITE_USERS}
                destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}/${USER_INVITE_PAGE}`}
            >
                <AddUserIcon fontSize="small" />
            </ActionButton>
        );
    }

    // Don't show button if not a Bentley Account Ddmin
    if (!getAccountAdminFromTokenBentley()) {
        return <div />;
    }

    // BID version of button which opens Bentley user management portal
    return (
        <ActionButton
            text={INVITE_USERS}
            tooltipTitle={ADD_USER_TOOLTIP}
            onClick={() => {
                window.open(`${getConfig().bentleyUserManagementUrl}/management/users`, '_blank');
            }}
        >
            <AddUserIcon fontSize="small" />
        </ActionButton>
    );
}

export function ListUsersPage() {
    setDocumentTitle('Users');
    const navigate = useNavigate();
    const isAdminOrBentleyEnabled = getAdminFromToken() || getConfig().bentleyIdEnabled;

    if (!isAdminOrBentleyEnabled) {
        return <Restricted message={RESTRICTED_NO_PERMISSION_USERS} />;
    }
    const selectUser = (userUuid: string) => {
        navigate(`${userUuid}`);
    };
    const segments: BreadcrumbSegment[] = [usersBreadcrumb];

    return (
        <PageContent
            breadcrumbSegments={segments}
            pageTitle={USERS_TITLE}
            titleActions={<InviteUsersButton />}
        >
            {getConfig().bentleyIdEnabled ? (
                <BentleyGenericUserList service="evo" rowClickHandler={selectUser} addMessages />
            ) : (
                <GenericUserList service="evo" rowClickHandler={selectUser} addMessages />
            )}
        </PageContent>
    );
}
