/* eslint-disable no-param-reassign */
import { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { Slice, createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import {
    GetObjectResponsePayload,
    SelectorTypeBoolean,
    SelectorTypeGetObjectResponse,
    SelectorTypeGetObjectResponseList,
    SelectorTypeString,
    SelectorTypeStringArray,
    StringArrayPayload,
    VisualizationState,
    VisualizationStatePayload,
} from './visualizationSlice.types';

export const initialState: VisualizationState = {
    selectedObjectIds: [],
    loadedObjects: [],
    overlays: { scalebar: true, orientation: true },
};
const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjects: SelectorTypeGetObjectResponseList = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string): SelectorTypeGetObjectResponse =>
    createSelector(visualizationState, (visualizationStateRoot) =>
        visualizationStateRoot.loadedObjects.find((item) => objectId.includes(item.object_id)),
    );

export const selectedObjectIds: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.selectedObjectIds,
);

export const getObjectSchemaByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => response?.object?.schema ?? '');

export const getObjectNameByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => response?.object?.name ?? '');

export const visualizationSlice: Slice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        updateSelection(state: VisualizationState, { payload }: StringArrayPayload) {
            state.selectedObjectIds = payload;
        },
        addToLoadedObjects(state: VisualizationState, { payload }: GetObjectResponsePayload) {
            const foundIndex = state.loadedObjects.findIndex(
                (item: GetObjectResponse) => item.object_id === payload.object_id,
            );
            if (foundIndex === -1) {
                state.loadedObjects.push(payload);
            }
        },
        removeFromLoadedObjects(state: VisualizationState, { payload }: StringArrayPayload) {
            state.loadedObjects = state.loadedObjects.filter(
                (item: GetObjectResponse) => !payload.includes(item.object_id),
            );
        },
        updateOverlays(state: VisualizationState, { payload }: VisualizationStatePayload) {
            state.overlays = { ...state.overlays, ...payload };
        },
    },
});
