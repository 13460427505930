import {
    OrgListedObject,
    useListObjectsByOrgQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { BoundingBoxControls } from '@local/map-viewer/dist/controls/BoundingBoxControls';
import { ClusteredObjectMarkers } from '@local/map-viewer/dist/layers/evo/clustering';
import { Loading } from '@local/map-viewer/dist/layers/Loading';
import { Search } from '@local/map-viewer/dist/layers/Search';
import { MapViewer } from '@local/map-viewer/dist/MapViewer';
import { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { capBounds } from '@local/map-viewer/dist/utils/capBounds';
import { getGeoJSONBoundingBox } from '@local/map-viewer/dist/utils/getGeoJSONBoundingBox';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { discoveryBreadcrumb } from 'src/utils/breadcrumbs';

import { DISCOVERY_TITLE } from '../../strings';
import { SchemaIcon } from '../workspacePage/workspaceContent/SchemaIcon';

export function DiscoverPage() {
    const { orgUuid } = useParams();
    const [query, setQuery] = useState('');
    const [objects, setObjects] = useState<OrgListedObject[]>();
    const [bounds, setBounds] = useState<BoundingBoxCoords | null>();
    const [boundingBox, setBoundingBox] = useState<BoundingBoxCoords | null>();

    // We provide either a specified bounding box, or the map's viewport (bounds) as a query filter
    const queryBounds = useMemo(() => {
        const bbox = boundingBox ?? bounds;
        return bbox ? getGeoJSONBoundingBox(capBounds(bbox)) : undefined;
    }, [boundingBox, bounds]);

    const { data, /* error, */ isLoading, isFetching } = useListObjectsByOrgQuery({
        orgId: orgUuid,
        geojsonBoundingBox: queryBounds,
    });

    useEffect(() => {
        if (!data?.objects || isLoading || isFetching) {
            return;
        }
        setObjects(data.objects);
    }, [data?.objects, isLoading, isFetching]);

    setDocumentTitle('Discover');

    const segments: BreadcrumbSegment[] = [discoveryBreadcrumb];

    return (
        <Grid container>
            <Breadcrumb segments={segments} medium />
            <TitleRow title={DISCOVERY_TITLE} />
            <MapViewer onBoundsChange={setBounds} setQuery={setQuery}>
                <Search query={query} setQuery={setQuery} />
                <BoundingBoxControls onChange={setBoundingBox} />
                {isLoading || (isFetching && <Loading />)}
                <ClusteredObjectMarkers objects={objects} iconCreateFunc={SchemaIcon} />
            </MapViewer>
        </Grid>
    );
}
