import {
    GetObjectByIdApiArg,
    GetObjectResponse,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { getUrlConfig } from '@local/api-clients/dist/utils/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    LINE_DEFAULT_MODE,
    LINE_DEFAULT_WIDTH,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
} from '@local/webviz/dist/context/snapshots/defaults';
import { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

import { GeoscienceLineObject, GeosciencePointObject } from '../../types';
import { generateAttributesSnapshot } from './attributeSnapshot';
import { CreateViewSnapshot } from './generateSnapshot.types';

export function createViewSnapshot(
    gooseObject: GetObjectResponse,
    params?: GetObjectByIdApiArg,
): CreateViewSnapshot {
    let viewId = '';
    const snapshot = generateSnapshot();
    if (!snapshot) {
        console.error(`Unsupported schema: ${gooseObject.object.schema}`);
    }

    function generateSnapshot() {
        const schema = extractSchema(gooseObject.object.schema);
        const objectId = gooseObject.object_id;
        switch (schema) {
            case Schemas.PointsetSchema: {
                viewId = toSuffixUid(objectId, ViewClass.Points);
                const pointsSnapshot = generatePointsSnapshot(
                    viewId,
                    gooseObject as GeosciencePointObject,
                );
                return pointsSnapshot;
            }
            case Schemas.DownholeIntervalsSchema: {
                if (!params) {
                    return undefined;
                }

                viewId = toSuffixUid(objectId, ViewClass.Lines);
                const linesSnapshot = generateTileset3DLinesSnapshot(
                    viewId,
                    gooseObject as GeoscienceLineObject,
                    params,
                );
                return linesSnapshot;
            }
            default:
                return undefined;
        }
    }

    return {
        viewId,
        snapshot,
    };
}

function generatePointsSnapshot(
    viewId: string,
    gooseObject: GeosciencePointObject,
): UpdateSnapshot | undefined {
    const {
        object_id: objectId,
        object: {
            locations: {
                coordinates: { width, length, data_type: dataType, data: dataId },
            },
        },
        links: { data },
    } = gooseObject;

    const dataObject = data.find(({ id }) => id === dataId);

    if (!dataObject?.download_url || !objectId) {
        return undefined;
    }

    const elementId = toSuffixUid(objectId, ElementClass.Points);
    const snapshot = {
        [elementId]: generateEntity(ElementClass.Points, {
            verticesUrl: {
                location: dataObject.download_url,
                shape: [length, width],
                dataType,
            },
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
        }),
    };
    return snapshot;
}

export function generateVisualizationServiceUrl(baseUrl: string, params: GetObjectByIdApiArg) {
    return `${baseUrl}/visualization/orgs/${params.orgId}/workspaces/${params.workspaceId}/geoscience-object/${params.objectId}`;
}

function generateTileset3DLinesSnapshot(
    viewId: string,
    gooseObject: GeoscienceLineObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot | undefined {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    let attributesSnapshot = {};
    let firstColorData = '';
    if ('attributes' in object && object.attributes?.length) {
        const firstAttribute = object.attributes[0];
        if (firstAttribute?.values?.data) {
            firstColorData = toSuffixUid(
                `attribute_${firstAttribute.values.data}`,
                UID_SUFFIXES.DATA,
            );
        }
        attributesSnapshot = object.attributes.reduce(
            (accumulator: UpdateSnapshot, { values: { data }, attribute_type }) => ({
                ...accumulator,
                ...generateAttributesSnapshot(data, attribute_type),
            }),
            {},
        );
    }

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        ...attributesSnapshot,
        [viewId]: generateEntity(ViewClass.Lines, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            width: LINE_DEFAULT_WIDTH,
            mode: LINE_DEFAULT_MODE,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}
